import { render, staticRenderFns } from "./TaskManagementCreated.vue?vue&type=template&id=6631a1a2"
import script from "./TaskManagementCreated.vue?vue&type=script&lang=js"
export * from "./TaskManagementCreated.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports