<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :block-content="false"
      :edit-content="true"
      :view-content="true"
      :viw-component="viwComponent"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :delete-content="true"
      :selectable="false"
      :cancel-content="true"
    >
      <template #cell(due_date)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <span
            :style="`color: ${data.item.is_late ? 'red' : 'black'};`"
          >
            {{ data.item.due_date }}
          </span>
        </slot>
      </template>
    </general-table>
    <!--  -->
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'https://gulftic-system.fci.group/api/task/created',
      addType: 'Create Task',
      addComponentName: 'create-task',
      editComponent: 'edit-task',
      viwComponent: 'view-task',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'issue', label: 'Task' },
        { key: 'priority', label: 'Priority' },
        { key: 'due_date', label: 'Due Date' },
        { key: 'description', label: 'Description' },
        { key: 'required_action', label: 'Required Action' },
        { key: 'status', label: 'Status' },
        { key: 'request_id', label: 'Request Id' },
        { key: 'created_by.name', label: 'Created Name' },
        { key: 'created_by.email', label: 'Created Email' },
        { key: 'created_by_type', label: 'Created By Type' },
        { key: 'actions' },
      ],
    }
  },

  computed: {

    apiUrl() {
      let base = 'https://gulftic-system.fci.group/api/task/created?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log(this.filterTypes('admin'))
  // },

  methods: {

    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },

  },
}
</script>

  <style></style>
